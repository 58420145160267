@import url("./assets/lib/hljs/styles/atom-one-light.css");
@import url("../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css");
@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import "./assets/scss/app.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
.addBtn {
  background-color: #2e6eb5;
  color: #ffffff;
  font-weight: 600;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.backEye {
  background-color: #3f51b5;
}

.backEye:hover {
  background-color: #464749;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

// mat table

.mat-column-sr,
.mat-column-action {
  flex: none;
  width: 10%;
}

mat-row,
mat-header-row,
mat-footer-row {
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-header-cell {
  font-size: 16px !important;
  color: black !important;
}

.mat-header-cell.mat-column-sr {
  display: grid;
}

.responsive_table {
  overflow-x: auto !important;
}
mat-cell{
  word-break: break-all;
  text-align: start;
}
mat-form-field {
  width: 100%;
}

.example-section {
  align-content: center;
  align-items: center;
}

.example-margin {
  margin: 0 10px;
}

.hoverable {
  text-align: center;
  position: relative;
  cursor: pointer;

  width: 150px;

  .hover-text {
    position: absolute;
    display: none;
    top: 50%;
    left: 56%;
    font-weight: 900;
    background-color: #142f49;
    padding: 3px;
    color: #ffffff !important;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .hover-text1 {
    position: absolute;
    display: none;
    top: 88%;
    left: 49%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .hover-docs {
    position: absolute;
    display: none;
    top: 74%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .background {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(221, 231, 243, 0);
    pointer-events: none;
    z-index: 1;
  }

  &:hover {
    .hover-text {
      white-space: nowrap;
      display: block;
    }

    .hover-text1 {
      white-space: nowrap;
      display: block;
    }

    .hover-docs {
      white-space: nowrap;
      display: block;
    }

    .background {
      display: block;
    }
  }
}

.mat-fab:not([class*="mat-elevation-z"]),
.mat-mini-fab:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 5px -1px rgba(250, 249, 249, 0),
    0px 6px 10px 0px rgba(255, 255, 255, 0),
    0px 1px 18px 0px rgba(255, 255, 255, 0) !important;
}

.mat-fab:not([class*="mat-elevation-z"]),
.mat-mini-fab:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 5px -1px rgb(255 251 251 / 0%),
    0px 6px 10px 0px rgb(255 255 255 / 0%),
    0px 1px 18px 0px rgb(255 255 255 / 0%) !important;
}

.g-translate {
  position: absolute !important;
  z-index: 11;
  right: 42%;
  top: 10px;
}

.fa-solid.fa-arrows-rotate {
  background: #4e84bc;
  position: absolute;
  top: 9px;
  right: 0px;
  border-radius: 7px;
  padding: 6px;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

.g-translate select {
  padding-left: 15px;
  width: 113%;
  background: transparent;
  box-shadow: 0px 0px 10px 0 rgb(177 177 177 / 48%);
  border: unset;
  border-radius: 10px;
  height: 36px;
}

@media (max-width: 768px) {
  .g-translate {
    position: absolute;
    right: 80px;
    top: 33px;
  }
}

@media (max-width: 576px) {
  .g-translate {
    position: absolute;
    right: 70px;
    top: 25px;
  }
}

@media (max-width: 450px) {
  .g-translate {
    position: absolute;
    right: 70px;
    top: 35px;
  }
}

.g-translate span {
  display: none;
}

.g-translate .skiptranslate.goog-te-gadget {
  font-size: 0;
}

.g-translate select:focus {
  box-shadow: 0px 0px 10px 0 #0119f17a;
}

.g-translate .svg-inline--fa {
  position: absolute;
  right: 18px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: rgb(0, 0, 0) !important;
  font-weight: 600;
}

#google_translate_element img {
  display: none !important;
}

.goog-te-banner-frame {
  display: none;
  height: 0 !important;
  visibility: hidden;
}

#google_translate_element {
  display: none;
}

#goog-gt-tt {
  color: #22222205;
  /* background-color: #ffffff; */
  border: 1px solid #eee;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  display: none !important;
  font-family: arial;
  font-size: 10pt;
  width: 420px;
  padding: 12px;
  position: absolute;
  z-index: 10000;
}

.goog-text-highlight {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 transparent !important;
}

::ng-deep {
  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    z-index: 9999 !important;
  }
  .cdk-overlay-connected-position-bounding-box {
    z-index: 99999 !important;
  }
}
